<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!-- 订单号 -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <span
            class="cur-pot"
            :class="row.orderId ? 'color-blue' : 'color-green'"
            @click="handleGoToOrder(row)"
        >{{ row.orderId ? row.orderNo : row.ratingId }}</span>
      </div>
<!--  物流类型    -->
      <div slot="itemSenderTypeSlot" slot-scope="row">
        <span :class="senderTypeClassType[row.senderType]">{{ computedSenderTypeMapText(row.senderType) }}</span>
      </div>
<!--  异常类型    -->
      <div slot="itemErrorTypeSlot" slot-scope="row">
        <span :class="errorTypeClassType.get(row.exceptionType)">{{ computedErrorTypeMapText(row.exceptionType) }}</span>
      </div>
<!-- 物流详情     -->
      <div slot="itemNatureSlot" slot-scope="row">
        <div v-if="row.logisticsSimpleInfo">
          <div>
            <span class="color-green" :class="errorTypeClassType.get(row.exceptionType)"  v-if="row.stateValue">【{{ row.stateValue }}】</span>
            <span class="color-blue">{{ row.logisticsSimpleInfo.context }}</span>
          </div>
          <div class="color-gray">时间：{{ row.logisticsSimpleInfo.time }}</div>
        </div>
        <div v-else class="color-red">暂无</div>
      </div>
<!--  地址    -->
      <div slot="itemAddressSlot" slot-scope="row">
        <div class="color-black">{{ row.consigneeRealname + '  ' + row.consigneeTelphone }}</div>
        <div class="color-black">{{ row.consigneeAddress }}</div>
      </div>
<!--   发货时间   -->
      <div slot="itemSenderAboutSlot" slot-scope="row">
        <div class="color-gray">{{ row.senderTime }}</div>
        <div class="color-orange" v-if="row.senderUserId">发货用户ID：【{{ row.senderUserId }}】</div>
      </div>
    </a-table>
  </div>
</template>
<script>
import {
  errorTypeClassType,
  errorTypeMapText,
  logisticsMonitoringListTableColumns, senderTypeClassType,
  senderTypeMapText
} from "@/views/cmsPage/dqPlatform/LogisticsMonitoring/_data"

export default {
  props: ['list', 'pagination'],
  components: {},
  data() {
    return {
      tableList: logisticsMonitoringListTableColumns,
      senderTypeClassType,
      errorTypeClassType,
    }
  },
  computed: {
    computedSenderTypeMapText() {
      return (value) => senderTypeMapText(value)
    },
    computedErrorTypeMapText() {
      return (value) => errorTypeMapText(value)
    }
  },
  methods: {
    /** 去往订单页面 */
    handleGoToOrder(row) {
      const origin = location.origin
      if (row.orderId) {
        const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${row.orderNo}`;
        window.open(url)
      }
      if (row.ratingId) {
        const url = `${origin}/#/cms/rating/manage?ratingId=${row.ratingId}`;
        window.open(url)
      }
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>