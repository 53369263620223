var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          pagination: _vm.pagination,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    staticClass: "cur-pot",
                    class: row.orderId ? "color-blue" : "color-green",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToOrder(row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.orderId ? row.orderNo : row.ratingId))]
                )
              ])
            }
          },
          {
            key: "itemSenderTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.senderTypeClassType[row.senderType] }, [
                  _vm._v(_vm._s(_vm.computedSenderTypeMapText(row.senderType)))
                ])
              ])
            }
          },
          {
            key: "itemErrorTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.errorTypeClassType.get(row.exceptionType) },
                  [
                    _vm._v(
                      _vm._s(_vm.computedErrorTypeMapText(row.exceptionType))
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemNatureSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.logisticsSimpleInfo
                  ? _c("div", [
                      _c("div", [
                        row.stateValue
                          ? _c(
                              "span",
                              {
                                staticClass: "color-green",
                                class: _vm.errorTypeClassType.get(
                                  row.exceptionType
                                )
                              },
                              [_vm._v("【" + _vm._s(row.stateValue) + "】")]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "color-blue" }, [
                          _vm._v(_vm._s(row.logisticsSimpleInfo.context))
                        ])
                      ]),
                      _c("div", { staticClass: "color-gray" }, [
                        _vm._v("时间：" + _vm._s(row.logisticsSimpleInfo.time))
                      ])
                    ])
                  : _c("div", { staticClass: "color-red" }, [_vm._v("暂无")])
              ])
            }
          },
          {
            key: "itemAddressSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-black" }, [
                  _vm._v(
                    _vm._s(row.consigneeRealname + " " + row.consigneeTelphone)
                  )
                ]),
                _c("div", { staticClass: "color-black" }, [
                  _vm._v(_vm._s(row.consigneeAddress))
                ])
              ])
            }
          },
          {
            key: "itemSenderAboutSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(_vm._s(row.senderTime))
                ]),
                row.senderUserId
                  ? _c("div", { staticClass: "color-orange" }, [
                      _vm._v("发货用户ID：【" + _vm._s(row.senderUserId) + "】")
                    ])
                  : _vm._e()
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }