var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c(
          "a-select",
          {
            staticClass: "w-150 ml-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "物流类型" },
            model: {
              value: _vm.params.senderType,
              callback: function($$v) {
                _vm.$set(_vm.params, "senderType", $$v)
              },
              expression: "params.senderType"
            }
          },
          _vm._l(_vm.senderTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "w-150 ml-5",
            attrs: { allowClear: "", showSearch: "", placeholder: "异常类型" },
            model: {
              value: _vm.params.exceptionType,
              callback: function($$v) {
                _vm.$set(_vm.params, "exceptionType", $$v)
              },
              expression: "params.exceptionType"
            }
          },
          [
            _vm._l(_vm.exceptionTypeList, function(item) {
              return [
                item.show
                  ? _c(
                      "a-select-option",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        ),
        _c(
          "a-button",
          { attrs: { type: "primary" }, on: { click: _vm.search } },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("TableList", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: { changePage: _vm.handleChangePage, success: _vm.getList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }