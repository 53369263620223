import {objArrMapObj} from "@/untils/otherEvent"
// 发货时间、物流类型、物流状态、异常类型、最新物流详细（截取部分展示）、订单号、发货人ID、

/** 物流监控-列表-table */
export const logisticsMonitoringListTableColumns = [
    {title: 'ID', align: 'center', dataIndex: 'id'},
    { title: "订单号/评级ID", align: "center", scopedSlots: { customRender: 'itemOrderNoSlot'}, width: 200 },
    { title: "物流单号", align: "center", dataIndex: 'expressNo' },
    { title: "物流类型", align: "center", scopedSlots: { customRender: 'itemSenderTypeSlot'}, width: 100 },
    { title: "异常类型", align: "center", scopedSlots: { customRender: 'itemErrorTypeSlot'}, width: 100 },
    { title: "最新物流详情", align: "center", scopedSlots: { customRender: "itemNatureSlot" }, width: 400 },
    { title: "地址", align: "center", scopedSlots: {customRender: 'itemAddressSlot'}, width: 400 },
    { title: "发货时间", align: "center", scopedSlots: { customRender: 'itemSenderAboutSlot'}, width: 200},
]

/** 物流类型 */
export const senderTypeList = [
    {name: '直发', value: 2},
    {name: '评级', value: 4},
]
export const senderTypeClassType = {
    2: 'color-blue',
    4: 'color-green'
}

/** 异常类型 */
export const exceptionTypeList = [
    {name: '查询异常', value: -1, show: true},
    {name: '处理中', value: 0, show: false},
    {name: '退签/拒签', value: 4, show: true},
    {name: '正常', value: 10, show: false},
]
export const errorTypeClassType = new Map([
    [-1, 'color-red'],
    [0, 'color-red'],
    [4, 'color-red'],
    [10, 'color-green']
])

export const senderTypeMapText = (value) => {
    return objArrMapObj('value', 'name', senderTypeList)[value]
}
export const errorTypeMapText = (value) => {
    return objArrMapObj('value', 'name', exceptionTypeList)[value]
}