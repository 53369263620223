<template>
  <div class="content">
    <div class="top">
      <a-select
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.senderType"
          placeholder="物流类型"
      >
        <a-select-option
            v-for="item of senderTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.exceptionType"
          placeholder="异常类型"
      >
        <template v-for="item of exceptionTypeList">
          <a-select-option
              v-if="item.show"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </template>
      </a-select>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/dqPlatform/LogisticsMonitoring/_components/TableList/index.vue"
import untils from "@/untils"
import {getListByLogisticsMonitoring} from "@/views/cmsPage/dqPlatform/LogisticsMonitoring/_apis"
import {exceptionTypeList, senderTypeList} from "@/views/cmsPage/dqPlatform/LogisticsMonitoring/_data"

export default {
  components: {
    TableList
  },
  data() {
    return {
      untils,
      data: [],
      tempData: [],
      exceptionTypeList,
      senderTypeList: senderTypeList,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        senderType: undefined,
        exceptionType: undefined,
      }
    };
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    async search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      await this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getListByLogisticsMonitoring(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      res.data.records.forEach(el => {
        el.logisticsSimpleInfo = JSON.parse(el.logisticsSimpleInfo)
      })
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
